import PropTypes from "prop-types"
import React from "react"
import Transition from "../components/Transition"
import "./Layout.css"
import { useState, useEffect } from "react"
import {
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth"
import { auth } from "../utils/firebase"

const Layout = ({ children, location }) => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState(null)
  const [user, setUser] = useState(null)

  useEffect(() => {
    // Listen for auth state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user)
    })

    // Cleanup subscription
    return () => unsubscribe()
  }, [])

  const handleSignIn = async (e) => {
    e.preventDefault()
    try {
      await signInWithEmailAndPassword(auth, email, password)
      setError(null)
    } catch (err) {
      setError(err.message)
    }
  }

  const handleSignOut = async () => {
    try {
      await signOut(auth)
    } catch (err) {
      setError(err.message)
    }
  }

  const handleResetPassword = async () => {
    if (!email) {
      setError("Please enter your email first.")
      return
    }

    try {
      await sendPasswordResetEmail(auth, email)
      setError(null)
      alert("Password reset email sent! Check your inbox.")
    } catch (err) {
      setError(err.message)
    }
  }

  if (user) {
    return (
      <div id="app" className="app">
        <script src="https://player.vimeo.com/api/player.js"></script>
        <button onClick={handleSignOut}>Sign Out</button>
        <Transition location={location}>{children}</Transition>
      </div>
    )
  }

  return (
    <div
      id="app"
      className="app"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <h1>Sign In</h1>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <form
        onSubmit={handleSignIn}
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0 auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "10px",
            gap: "8px",
          }}
        >
          <label
            style={{
              fontFamily: "Georgia",
              width: "100px",
            }}
          >
            Email:
          </label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={{
              width: "200px",
              height: "20px",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "10px",
            gap: "8px",
          }}
        >
          <label
            style={{
              fontFamily: "Georgia",
              width: "100px",
            }}
          >
            Password:
          </label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            style={{
              width: "200px",
              height: "20px",
            }}
          />
        </div>
        <button
          type="submit"
          style={{
            marginTop: "10px",
            backgroundColor: "var(--clr-primary)",
            borderRadius: "4px",
            padding: "8px",
            height: "40px",
            width: "100px",
          }}
        >
          Sign In
        </button>
        <button
          type="button"
          onClick={handleResetPassword}
          style={{
            marginTop: "10px",
            backgroundColor: "transparent",
            color: "var(--clr-primary)",
            border: "none",
            cursor: "pointer",
          }}
        >
          Forgot Password?
        </button>
      </form>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
